import React from "react"
import Layout from '../components/Layout'
import PageHeading from '../components/PageHeading'
import Project from '../components/Projects/Project'
import projectsImg from '../assets/projects.svg'
import projectsBgImg from '../assets/projectsbg.jpg'
import Meta from '../components/Meta'

const META_DESCRIPTION = 'Here are some full-stack products that we have worked on. We are looking to grow this list, and include some more great looking projects. Perhaps your project could end up on this page!'

const loadProjectsImages = (projectName, count) => {
  const result = [];
  for (let i = 1; i <= count; i++) {
    try {
      const img = require(`../assets/projects/${projectName}/${i}.jpg`)
      result.push(img);
    } catch (e) {

    }
  }
  return result;
}

const projects = [
  {
    id: 'lazylocal',
    name: 'Lazylocal',
    subtitle: 'Launched May 2018',
    description: 'Manual internationalization/translation for applications can be costly and difficult. Lazylocal combines the automated and manual process to save cost. A developer may upload their application text to the SaaS, select which languages they would like to support, and automatic translations will be provided using third-party translation providers. A professional translator may also improve the translations manually later on using the dashboard. Lazylocal provides application libraries for Android, UWP, React, Angular and Vanilla JS to provide live translation updates without any software updates!',
    photos: loadProjectsImages('lazylocal', 8)
  },
  {
    id: 'venuemob',
    name: 'Venuemob',
    subtitle: 'July 2017 - February 2019',
    description: 'Venuemob is an Australian venue booking platform and startup. In mid-2017, the venues requested a web application in order for them to manage their venue listing and communicate with customers on the platform. Working within a small team, a full-stack Node/Koa/React application was created, and continuously developed to ensure success for Venuemob and their associated venues. The app allowed venues to manage their pricing, photos, promotions, reviews. As a customized CRM, the app also allowed for direct communication between the venue and the customer.',
    photos: loadProjectsImages('venuemob', 15)
  }
]

export default function Projects() {
  return (
    <Layout>
      <Meta pageName='Projects' description={META_DESCRIPTION} />
      <PageHeading bgImg={projectsBgImg} symbolImg={projectsImg} title='Our Projects' />
      {projects.map((v, i) => (
        <Project {...v} key={i} altSide={i % 2 === 1} />
      ))}
    </Layout>
  )
}
