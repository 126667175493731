import React from 'react';
import Slider from 'react-slick';
import styles from './index.module.css'
import { c } from '../../../util'

const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  className: styles.slider
};

export default function Project({ id, name, subtitle, description, photos, altSide }) {

  const backgroundColor = altSide ? '#eeecfc' : '#ffffff'

  return (
    <div id={id} className={c([styles.root, altSide && styles.rootReverse])} style={{ backgroundColor }}>
      <div className={altSide ? styles.sliderCtrAlt : styles.sliderCtr}>
        <Slider {...sliderSettings}>
          {photos.map((v, i) => (
            <img src={v} alt={'' + i} key={i} />
          ))}
        </Slider>
      </div>
      <div className={styles.content}>
        <h2 className={styles.name}>
          {name}
        </h2>
        <div className={styles.subtitle}>
          {subtitle}
        </div>
        <div>
          {description}
        </div>
      </div>
    </div>
  );
}
